body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 900px;
    margin: 0 auto;
  }

  